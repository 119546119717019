import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const SiennicaCmentarzParafialny = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Cmentarz Parafialny w Siennicy" />
      <ReturnToPrevPage to={'/gminy/siennica'}>
        Materiały dotyczące gminy Siennica
      </ReturnToPrevPage>
      <Header1>Cmentarz Parafialny w Siennicy</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <VideoPlayer videoId={895166092} />
        <p>
          Siennica w swoich losach wiele lata było kluczowym miastem na mapie
          mazowsze, a mimo powolnego wchodzenia w cień kolei terespolskiej i
          rosnącego znaczenia Mińska Mazowieckiego. Jednym z dowodów świetności
          tego ośrodka jest cmentarz grzebalny. Pomniki-zabytki, które tam się
          znajdują są ilustracją zamożności a tym samym wpływowości mieszkańców
          parafii. Zachęcamy do obejrzenia zdjęć i materiału wideo na którym
          Waldemar Piekarski ze Stowarzyszenia Przyjaciół Ziemi Siennickiej
          prezentuje zarys cmentarnych historii.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/siennica/cmentarz-parafialny/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: { relativePath: { regex: "/siennica/cmentarz-parafialny/" } }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default SiennicaCmentarzParafialny
